import { SupportedLanguage, systemDefaultLanguageCode } from '../types/Languages';
import i18n from '../i18n';
import { Translations } from '../models/Translation';
import { FormAction } from '../models/Form';

export default class LanguageUtils {
  public static getTranslation<TKey extends string, TTranslations extends Translations<TKey>>(
    propertyName: TKey,
    translations: TTranslations,
    lang: SupportedLanguage | null = null,
  ): string {
    const language: SupportedLanguage = lang || i18n.language;
    const enFallback = translations?.[systemDefaultLanguageCode]?.[propertyName] || '';

    // Temporarily falling back to DE if no EN is availble. This should rather be a fallback
    // to the client default language
    const deFallback = translations?.['de']?.[propertyName] || '';

    return translations?.[language]?.[propertyName] || enFallback || deFallback || '';
  }

  public static getActionDataTranslation(action: FormAction, language?: SupportedLanguage): NonNullable<FormAction['data']> {
    if (!action.translations) return action.data ?? {};

    return { ...action.data, ...action.translations[systemDefaultLanguageCode], ...action.translations[language ?? i18n.language] };
  }
}
